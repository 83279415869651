<script setup lang="ts">
import { computed, ref } from "vue";
import { format } from "date-fns";
import { useStore } from "@/store";
import HeaderBar from "@/components/ui/HeaderBar.vue";
import DashboardLinks from "@/components/DashboardLinks.vue";
import DashboardVeo from "@/components/DashboardVeo.vue";
import DashboardTeams from "@/components/DashboardTeams.vue";
import AdminDashboard from "@/components/AdminDashboard.vue";
import backgroundImage from "@/assets/background1.jpg";

const store = useStore();
const isAdmin = computed(() => store.state.user.isAdmin);
const displayName = computed(() => store.getters.displayName);
const showAlert = ref(true);

function hideAlert() {
  showAlert.value = false;
}
</script>

<template>
  <div
    class="h-full min-h-screen bg-no-repeat bg-cover"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <div class="h-full min-h-screen bg-gradient-to-b from-[rgba(0,0,0,0.8)]">
      <div class="py-8 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 space-y-6">
        <div
          v-if="showAlert"
          class="flex items-center gap-x-6 bg-blue-700 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 rounded-md"
        >
          <p class="text-sm leading-6 text-white">
            <a href="#">
              <strong class="font-semibold">ALERT</strong
              ><svg
                viewBox="0 0 2 2"
                class="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx="1" cy="1" r="1" /></svg
              >Site may be outdated. Practice days, times and locations may be
              incorrect. We are working on updates to this portal.
            </a>
          </p>
          <div class="flex flex-1 justify-end">
            <button
              type="button"
              class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              @click="hideAlert"
            >
              <span class="sr-only">Dismiss</span>
              <svg
                class="h-5 w-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
              >
                <path
                  d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <HeaderBar class="sm:items-center">
          <span v-if="displayName" class="text-white"
            >Welcome, {{ displayName }}!</span
          >
          <template #actions>
            <span class="text-white text-xs lg:text-base">
              {{ format(new Date(), "EEEE, MMMM do, yyyy") }}
            </span>
          </template>
        </HeaderBar>
        <main>
          <div v-if="isAdmin">
            <AdminDashboard />
          </div>
          <div v-else class="py-2 gap-8 xl:gap-16 grid grid-cols-6">
            <div class="order-2 col-span-6 lg:order-1 lg:col-span-3">
              <DashboardLinks />
            </div>
            <div class="order-1 col-span-6 lg:order-2 lg:col-span-3 space-y-16">
              <div class="grid grid-cols-6">
                <DashboardVeo class="order-2 col-span-6 lg:order-1 lg:mb-8" />
                <DashboardTeams
                  class="order-1 mb-8 col-span-6 lg:order-2 lg:mb-0"
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
